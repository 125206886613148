/* global hljs */

document.addEventListener("DOMContentLoaded", () => {

  /*
   * Guide stuff
  */
  const guide = document.querySelector('.guide[data-page]')
  if(guide) {
    // add subheadings to menu
    const guideName = guide.getAttribute('data-page')
    const headings = guide.querySelectorAll('h2:not(.subheading)')
    if(headings) {
      const headingsEl = document.createElement('ul')
      headingsEl.classList.add('subheadings')
      headings.forEach(heading => {
        const li = document.createElement('li')
        const link = document.createElement('a')
        li.classList.add('subheading')
        link.innerText = heading.innerText
        link.href = `#${heading.id}`
        li.appendChild(link)
        headingsEl.appendChild(li)
      })
      const guideNavItem = guide.querySelector(`nav.guides ul li.${guideName}`)
      guideNavItem.classList.add('current')
      guideNavItem.appendChild(headingsEl)
    }
    // set nav height
    const nav = guide.querySelector('nav.guides')
    setGuideNavHeight(guide, nav)
    window.addEventListener('resize', () => {
      setGuideNavHeight(guide, nav)
    })
    // enable guide button on mobile
    const guidesButton = document.querySelector('#guides-button')
    guidesButton.addEventListener('click', () => {
      const className = 'visible'
      if( nav.classList.contains(className) ) {
        nav.classList.remove('visible')
      }
      else {
        nav.classList.add('visible')
      }
    })
    document.querySelectorAll('.guides a').forEach(link => {
      link.addEventListener('click', () => {
        nav.classList.remove('visible')
      })
    })
  }
  function setGuideNavHeight(guide, nav) {
    // Get original guide height before we start changing the nav (which will affect the parent height)
    let guideHeight = guide.offsetHeight
    if( guide.hasAttribute('data-original-height') ) {
      guideHeight = guide.getAttribute('data-original-height')
    }
    else {
      guide.setAttribute('data-original-height', guideHeight)
    }
    // Figure out what the nav height should be
    const navOffsetTop = nav.getBoundingClientRect().top
    let height = window.innerHeight
    // If guide contents overflow the window size, use the guide height.
    // Otherwise, use the window height (minus the top offset)
    if(guideHeight < window.innerHeight) {
      height = window.innerHeight - navOffsetTop
    }
    // Update as CSS var
    const root = document.documentElement
    root.style.setProperty('--guide-nav-height', height + 'px')
  }

  /*
   * Identify code blocks from highlight.js
   */
  hljs.highlightAll();
  const codeEls = document.querySelectorAll('.hljs')
  codeEls.forEach(el => {
    const classes = el.getAttribute('class')
    const languages = classes.match(/(css|javascript|html)/g)
    languages.forEach(lang => {
      el.parentNode.classList.add('code')
      el.parentNode.classList.add(lang)
    })
  })

  /*
   * Compensate for fixed header when scrolling to anchor links
   */
   const anchorLinks = document.querySelectorAll('a[href*="#"]')
   const fixedElementSelector = '#site-header'
   anchorLinks.forEach( link => {
     const el = document.querySelector( link.getAttribute('href') )
     const fixedEl = document.querySelector(fixedElementSelector)
     if(! fixedEl) return
     link.addEventListener('click', (e) => {
       e.preventDefault()
       const yOffset = (fixedEl.offsetHeight + 10) * -1
       const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
       window.scrollTo({top: y, behavior: 'smooth'})
     })
   } )

   /*
    * Open external links in new tab
    */
    document.querySelectorAll('a[href*="https://"]').forEach(link => {
      link.setAttribute('target', '_blank')
    })


})
